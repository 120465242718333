// Colours
$brand-red: #cc0000;
$brand-red-mid: #9e1733;
$brand-red-dark: #641333;
$brand-orange: #fe7822;
$brand-purple: #4c1d5e;
$grey-light: #f7f7f7;
$grey: #ededed;
$greyer: #e4e1e1;
$grey-pearl: #fcfcfc;
$grey-silver: #cccccc;
$grey-mid: #333;
$grey-dark: #363230;
$error: #db0000;
$error-light: #ffbaba;
$success: #55a630;
$success-light: #dff2bf;
$amber: #fcb941;
$amber-dark: #efaa2a;
$amber-light: #feefb3;
$twitter: #1d9bf0;
$linkedin: #0a66c2;
$blue-light: #dcecfb;
$instagram: white;

$brand-red-fade: #f9e5e5;
$brand-red-mid-fade: #f5e7ea;
$brand-red-dark-fade: #efe7ea;
$brand-orange-fade: #fff1e8;
$brand-purple-fade: #ede8ef;

// Text and Font
$heading-font: 'DM Sans', sans-serif;
$body-font: 'Roboto', sans-serif;
$fs-base: 18px;

// Borders
$border-radius: 0.5em;

// Shadows
$shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
$shadow-inset: 0px 2px 4px rgba(0, 0, 0, 0.05) inset, 0px 4px 8px rgba(0, 0, 0, 0.05) inset;
$shadow-flipped: 0px -2px 4px rgba(0, 0, 0, 0.05), 0px -4px 8px rgba(0, 0, 0, 0.05);
$shadow-flipped-inset: 0px -2px 4px rgba(0, 0, 0, 0.05) inset, 0px -4px 8px rgba(0, 0, 0, 0.05) inset;

// Padding
$container-padding-l: 8.6rem;
$container-padding-m: 3.6rem;
$container-padding-s: 1.6rem;

// Spacing
$sp-lg: 2rem;
$sp-md: 1.5rem;
$sp-sm: 1rem;
$sp-xs: 0.5rem;

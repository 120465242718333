// variables: $bgColour, $textColour, $bgHoverColour, $textHoverColour

.primary-button {
  @include button($brand-red-mid, white, $brand-red-mid-fade, $brand-red-mid);
}

.secondary-button {
  @include button($brand-red-mid-fade, $brand-red-mid, $brand-red-mid, white);
}

.cancel-button {
  @include button(white, $brand-red-mid, $brand-red-mid, white);
}

.block-red-button {
  @include button($brand-red, white, $brand-red-fade, $brand-red);
}

.faded-red-button {
  @include button($brand-red-fade, $brand-red, $brand-red, white);
}

.button-link {
  text-decoration: none;
  color: unset;

  &:hover {
    text-decoration: none;
    color: unset;
  }
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0 5px;

  &[disabled] {
    i {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  i {
    color: $grey-dark;
    font-size: 18px;

    &:hover {
      cursor: pointer;
    }
  }
}

.select-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  pointer-events: none;
  font-weight: 300;

  select {
    font-size: 14px;
  }

  // &::before {
  //   content: '▼';
  //   min-width: 20px;
  //   height: calc(100% - 10px);
  //   position: absolute;
  //   right: 5px;
  //   top: 57%;
  //   transform: translateY(-50%);
  //   text-align: center;
  //   font-size: 0.8em;
  //   color: $grey-mid;
  // }
}

.select-wrapper--pointer {
  pointer-events: auto;
}

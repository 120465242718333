.notes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-x: auto;
  padding: 2rem;

  .button-container {
    gap: 1rem;
  }
}

.modal__note {
  white-space: pre-wrap;
}

.notes__add-note {
  margin-bottom: 10px;
}

.modal__message {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 10px;
  }
}

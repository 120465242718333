@mixin scrollbar($width: 10px, $thumb-color: $grey-silver, $thumb-track-color: $grey-pearl) {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-scrollbar {
    width: $width; /* Remove scrollbar space */
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border: none;
    outline: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $thumb-track-color; /* Optional: just make scrollbar invisible */
    border: none;
    outline: none;
  }
}

.footer {
  border-top: 1px solid $greyer;
  font-size: 0.9em;
  background: white;
  margin-top: auto;
}

.footer__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  max-width: 1600px;
}

.footer__logo {
  height: 50px;
}

.footer__link {
  padding-left: 20px;

  @media (max-width: 380px) {
    margin-top: 10px;
    padding-left: 0;
  }
}

.incident-form {
  padding-bottom: 0;

  .page {
    padding-bottom: 1rem;
  }

  .form-container {
    background: white;
    padding: 2rem;
    padding-bottom: 0;

    .inner-container {
      max-width: 1000px;
      margin: auto;
      padding: 1rem 0 3rem;
    }
  }
}

.form__header {
  margin: 20px 0;
  font-size: 1.8em;

  @media (min-width: 400px) {
    margin: 20px 0;
  }
}

.form__field {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    &::after {
      color: $brand-red;
    }
  }

  &--last,
  &--full {
    width: 100%;
    margin-right: 100%;

    label {
      flex-basis: 17.5%;
    }

    &.field-customCheckbox {
      label {
        flex-basis: auto;
      }
    }
  }

  &--no-spacing {
    margin-bottom: 0;
  }

  &.required > label::after {
    display: inline;
    content: '*';
    font-size: 1.2em;
  }

  .field-wrap {
    margin-top: 5px;
  }

  .hint {
    font-size: 0.9em;
    font-style: italic;
    margin-top: 5px;
    margin-right: 52px;
    white-space: pre-wrap;
  }

  .errors {
    width: 100%;
    padding-top: 5px;
    font-size: 0.9em;
    color: $error;
  }

  &.error label {
    font-weight: 500;
    color: $error;
  }

  &.field-text {
    width: 100%;

    label {
      display: none;
    }
  }

  &.field-customSubmit {
    width: auto;
    margin-right: 20px;

    &.form__field--incident {
      width: 100%;
      justify-content: center;
      margin-bottom: 0;
      text-align: center;
    }

    label {
      display: none;
    }

    .hint {
      margin-top: 10px;
      margin-right: 0;
      text-align: center;
    }

    .field-wrap {
      flex-grow: 0;
      margin: 0;
    }
  }

  &.field-customRadios {
    .field-wrap {
      max-width: 100%;
    }

    .inline-fields {
      label {
        display: block;
        width: 100%;
        margin-top: 10px;
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  &.field-textArea {
    align-items: flex-start;
  }

  @media (max-width: 840px) {
    display: flex;

    &.field-customCheckbox {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      label {
        order: 2;
      }

      .field-wrap {
        order: 1;
        margin-top: 0;
        margin-right: 10px;
      }
    }
  }

  @media (min-width: 841px) {
    // width: 50%;
    // flex-direction: row;
    // align-items: center;
    // flex-wrap: wrap;

    .field-wrap {
      flex-grow: 1;
      max-width: 300px;
      margin-top: 0;
      margin-right: 40px;
    }

    &--full {
      width: 100%;
    }

    &--reverse {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .field-wrap {
        flex-grow: 0;
        margin-right: 0;
      }

      .input {
        padding-right: 0;
      }
    }
  }
}

.form__submit--center {
  input {
    margin: 0 auto;
  }
}

.form__file-upload {
  display: flex;
  width: 100%;

  @media (max-width: 1000px) {
    justify-content: flex-start;

    .field-wrap {
      max-width: auto;
      width: 100%;
      max-width: inherit;
    }
  }

  label {
    flex-basis: 19%;
  }

  @media (min-width: 1001px) {
    .field-wrap {
      max-width: 81%;
    }
  }
}

.vue-form-generator {
  display: flex;
  flex-direction: column;

  .field-input {
    .wrapper {
      width: 100%;
    }

    input[type='radio'] {
      width: 100%;
    }

    input[type='color'] {
      width: 60px;
    }

    input[type='range'] {
      padding: 0;
    }

    .helper {
      margin: auto 0.5em;
    }
  }

  .action {
    margin-right: 10px;
    color: #757575;
  }

  .field-radios {
    .radio-list {
      label {
        display: block;

        input[type='radio'] {
          margin-right: 5px;
        }
      }
    }
  }

  .field-submit input {
    // Default bootstrap primary button style
    color: #fff !important;
    background-color: #337ab7 !important;
    border-color: #2e6da4 !important;
  }
}

.hint {
  width: 100%;
  font-size: 0.9em;
  color: $grey-mid;
}

.duration {
  display: flex;
}

.duration__input {
  flex-basis: 20%;
  margin-right: 10px;
}

.field-button {
  width: auto;
}

.flatpickr-input.on-mobile {
  display: none;
}

.flatpickr-calendar.open {
  z-index: 30;
}

.field-custom-submit {
  position: relative;

  .v-spinner {
    padding: 0;
    margin-top: 2px;
  }
}

.company-search {
  background: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.company-search__form {
  display: flex;
  flex-direction: row;
  width: 50%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.company-search__input {
  margin-right: 20px;
  padding: 10px;
}

.company-search__message {
  font-style: italic;
}

.companies-found {
  margin: 20px 0 10px;
  font-style: italic;
}

.input__default--radio {
  &:checked~.input__styled--radio {
    background-color: $brand-red;
    border: 0;
  }
}

.input__styled--radio {
  border-radius: 50%;

  &:after {
    left: 7px;
    top: 7px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #fff;
  }
}
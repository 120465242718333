.steps {
  display: flex;
  width: calc(100% - 200px);
  margin: 60px auto;

  @media (max-width: 620px) {
    width: 100%;
    margin: 5px auto;
  }

  &__step {
    position: relative;
    flex-grow: 1;
    flex-basis: 100%;
  }

  &__shape {
    position: relative;
    width: 20px;
    height: 20px;
    display: block;
    z-index: 20;
    margin-bottom: 10px;
  }

  &__line-container {
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    text-align: center;
  }

  &__line {
    position: relative;
    left: 50%;
    width: 100%;
    height: 4px;
  }

  &__circle {
    fill: #fff;
    transition: 0.15s fill;

    &--active {
      fill: $brand-red;
    }
  }

  &__description {
    position: relative;
    left: 10px;
    top: 20px;

    @media (max-width: 620px) {
      display: none;
    }
  }

  &__description--active {
    font-weight: 500;
  }
}

.steps__step--last {
  max-width: 20px;
  white-space: nowrap;
  flex-grow: 0;

  .steps__line-container {
    position: relative;
  }

  .steps__description {
    top: 0;
    left: -100%;
    transform: translateX(-100%);
    margin-top: 15px;
  }
}

.steps--clickable .steps__step {
  &:hover {
    cursor: pointer;
  }
}
.multiselect {
  position: relative;
}

.multiselect__content-wrapper {
  display: block;
  position: absolute;
  z-index: 10;
  width: 100%;
  overflow: auto;
  background: white;
}

.multiselect__content {
  margin: 0;
  padding: 0;
  width: 100%;
}

.multiselect__element {
  list-style: none;
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option--highlight {
  background-color: $brand-red;
  color: #fff;
}
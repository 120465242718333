body {
  overflow-x: hidden;
  margin: 0;
  font-family: $body-font;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
  background: $grey-light;

  @include scrollbar;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: $heading-font;
  font-weight: 700;

  a {
    font-weight: normal;
  }
}

// h1 {
//   font-family: $heading-font;
//   font-size: 25px;
//   font-weight: 300;
// }

p {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.4em;
}

a {
  font-weight: 400;
  color: $brand-red;
  text-decoration: none;

  &:hover {
    color: $brand-red-mid;
    text-decoration: underline;
  }
}

ul,
ol {
  line-height: 1.5em;
}

fieldset {
  margin: 0;
  padding: 20px;
  order: 2;
  background-color: white;
  border: 1px solid $grey;

  // Bit of a hack
  &:first-of-type:not(:only-child) {
    .field-customSubmit {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &:not(:first-of-type),
  &:only-child {
    order: 1;
    margin-bottom: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 50%);
  }
}

input {
  margin-left: 0;

  &[type='submit'],
  &[type='button'] {
    @include button($brand-red-mid, white, $brand-red-mid-fade, $brand-red-mid);
  }

  &[type='datetime-local'] {
    min-width: 96%;
  }

  &[type='color'] {
    margin-right: 5px;
  }
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
textarea {
  display: block;
  padding: 0.8rem 1rem;
  box-sizing: border-box !important;
  border: 0;
  border-bottom: 3px solid $grey-dark;
  outline: none;
  font-weight: 600;
  border-radius: none;
  background-color: #f7f7f7;
  min-width: 100%;

  &:focus {
    border-color: $brand-red;
  }
  &:disabled {
    background-color: #fff;
  }
}

select,
input[type='datetime-local'],
input[type='text'].flatpickr-input {
  display: block;
  padding: 0.8rem 1rem;
  box-sizing: border-box !important;
  border: 0;
  border-bottom: 3px solid $grey-dark;
  outline: none;
  font-weight: 600;
  border-radius: none;
  min-width: 100%;
  appearance: menulist;
  background-color: #f7f7f7;
  pointer-events: auto;
  // -webkit-appearance: none;
  // -moz-appearance: none;

  // &::-ms-expand {
  //   display: none;
  // }
}

label {
  margin-right: 10px;
}

strong {
  display: inline-block;
  font-weight: 500;
}

i {
  font-style: normal;
}

.bar_chart {
  max-height: 400px;
}

@-webkit-keyframes v-pulseStretchDelay {
  0%,
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-opacity: 1;
    opacity: 1;
  }

  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-opacity: 0.7;
    opacity: 0.7;
  }
}

@keyframes v-pulseStretchDelay {
  0%,
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-opacity: 1;
    opacity: 1;
  }

  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-opacity: 0.7;
    opacity: 0.7;
  }
}

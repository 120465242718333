.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3rem;
}

.page {
  min-height: 74dvh;
  max-width: 1600px;
  padding-bottom: 5rem;

  &.white {
    background: white;
  }

  &.home {
    display: grid;
    grid-gap: 1rem;
    justify-content: center;

    @media (min-width: 920px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 425px));
    }
    @media (min-width: 1300px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 500px));
    }
    @media (min-width: 1330px) {
      grid-gap: 3rem;
    }
  }

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__card {
    max-height: 550px;
    padding: $sp-lg;
    background: white;
    box-shadow: $shadow;

    .page__header {
      min-height: 140px;
    }

    h1 {
      padding-bottom: 0.5rem;
    }

    i {
      cursor: pointer;
    }

    @media screen and (max-width: 900px) {
      flex-basis: 100%;
    }
  }

  &__section {
    padding: $sp-lg;
    background: white;

    @media screen and (max-width: 900px) {
      flex-basis: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 440px) {
      display: block;
    }
  }

  &__header--large {
    margin-bottom: 30px;
  }

  &__title {
    margin: 0;

    i {
      color: $grey-mid;
    }
  }

  &__subtitle {
    color: $grey-mid;
  }

  &__actions {
    display: flex;
  }

  &__action {
    max-width: none;
    margin: 0;
    margin-left: 10px;
    align-self: center;
  }

  &__action--full {
    &.button-link {
      display: flex;
      margin-bottom: 1rem;
    }

    @media (max-width: 500px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  &__action--first {
    @media (max-width: 500px) {
      margin-left: 0;
    }
  }
}

.box {
  display: flex;
  justify-content: center;
  padding: 40px;
  order: 2;
  background-color: $grey-light;
  border: 1px solid $grey;
}

.box__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box__message {
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
}

.box__icon.box__icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

.list-view {
  width: 100%;
  border: 1px solid $grey;
  border-bottom: 0;
  border-right: 0;

  &:not(.list-view--no-stack) {
    @media (max-width: 500px) {
      border: 0;
    }
  }
}

.list-view:not(.list-view--no-stack) {

  .list-view__header,
  .list-view__row {
    @media (max-width: 500px) {
      flex-direction: column;
      margin-bottom: 20px;
      border: 1px solid $grey;
      border-bottom: 0;
      border-right: 0;

      >div {
        justify-content: center;
      }

      >div:first-child {
        font-weight: 500;
      }
    }
  }
}

.list-view--inline {
  margin-top: 20px;
}

.list-view--compact {

  .list-view__header,
  .list-view__row {
    >div {
      padding: 7px;
    }
  }
}

.list-view__header,
.list-view__row {
  display: flex;

  >div {
    flex: 1;
    flex-basis: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    padding: 10px;
    background-color: #fff;
    border-right: 1px solid $grey;
    border-bottom: 1px solid $grey;
  }

  @media (min-width: 500px) {
    &:nth-child(odd) div {
      background-color: $grey-light;
    }
  }
}

.list-view--panel {
  .list-view__row {
    >div {
      background-color: #fff;

      &:nth-child(odd) div {
        background-color: #fff;
      }
    }
  }

  .list-view__header>div {
    background-color: $grey;
    color: $grey-dark;
  }
}

.list-view__header {
  >div {
    font-weight: 500;
    text-align: center;
  }

  @media (max-width: 500px) {
    display: none;
  }
}

.list-view__row {
  i {
    color: $grey-mid;
    font-size: 1rem;
  }

  a {
    padding: 0 5px;
  }

  >div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-word;
  }

  .notes-column {
    position: relative;

    span {
      position: absolute;
      left: 52%;
      top: 5px;
      background: white;
      border-radius: 50%;
      border: 1px solid $grey-mid;
      width: 17px;
      height: 17px;
      color: $grey-mid;
    }

    i {
      font-size: 1.5rem;
      color: $grey-mid;
    }
  }
}

.list-view__small-value {
  font-size: 14px;
  margin-left: 10px;
  vertical-align: super;

  @media (min-width: 500px) {
    display: none;
  }
}

div.list-view__small-column {
  flex-basis: 50%;
  justify-content: center;
  flex-wrap: nowrap;
}

div.list-view__center-column {
  justify-content: center;
}
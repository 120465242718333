






















































































.download-link {
  margin-right: 10px;
}
.button_colour {
  background-color: v-bind(color)
}

.actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem;

  @media (max-width: 500px) {
    margin-top: 10px;
  }
}

.actions__action {
  margin-right: 10px;
  color: #757575;

  &:last-child {
    margin-right: 0;
  }
}

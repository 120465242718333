.input {
  position: relative;
  width: 22px;
  min-height: 22px;
  padding-left: 30px;

  @media (min-width: 840px) {
    padding-right: 30px;
  }
}

.input--spacing {
  margin-left: 10px;
}

.input__default {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked~.input__styled:after {
    display: block;
  }
}

.input__styled {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid $grey;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}

.input__styled--checkbox {
  margin-right: 10px;
  border-radius: 3px;

  &:after {
    top: 2px;
    left: 7px;
    width: 5px;
    height: 12px;
    border: solid $brand-red-mid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.is-checked {
  .input__styled--checkbox,
  &.input__styled--checkbox {
    background: $brand-red;
  }
}

.listbox {
  .list-row {
    margin-bottom: 10px;
  }
  @media (min-width: 500px) {
    display: flex;
  }
}

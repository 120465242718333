@media print {
  .hide-for-print,
  button,
  .button,
  .header,
  .footer__terms {
    display: none;
  }

  .view {
    padding-top: 20px;
  }

  .incident {
    .list-view__header > div:first-child,
    .list-view__row > div:first-child {
      flex-basis: 50%;
    }
  }
}

.charts {
  background: white;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.charts--tabs.charts--tabs {
  margin: 0;
  border-bottom: 0;

  &:last-child {
    border-top: 0;
    border-bottom: 1px solid $grey;
  }
}

.charts__chart {
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;

  @media (max-width: 550px) {
    width: 100%;
  }
}

.charts__chart--spacing {
  margin-bottom: 30px;
}
.v--modal {
  overflow: unset !important;
}

.vue-dialog {
  padding: 0 !important;

  @media (max-width: 500px) {
    max-width: 95%;
    margin: 0 10px;
  }

  .vue-dialog-buttons {
    padding: 1rem;
    gap: 1rem;

    button {
      @include button(white, $brand-red-mid, $brand-red-mid, white);

      &:first-child {
        @include button($brand-red-mid, white, $brand-red-mid-fade, $brand-red-mid);
      }
    }
  }

  .dialog-c-title {
    font-weight: 500 !important;
  }
}

.modal__close {
  position: absolute;
  cursor: pointer;
  right: -17px;
  top: -15px;
  background: $brand-red-mid;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  z-index: 10000;

  i {
    font-size: 0.75rem;
    color: white;
  }

  &:hover {
    background-color: $brand-red-mid-fade;

    i {
      color: $brand-red-mid;
    }
  }
}

.modal__message {
  height: 100%;
  padding: 2rem;
  overflow-y: auto;

  @include scrollbar(7px, $brand-red-mid, $brand-red-mid-fade);

  h2 {
    font-size: 1rem;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }

  ul {
    margin: 0;
    padding-bottom: 2rem;
  }

  p {
    padding-bottom: 1rem;
  }
}

.panel {
  position: relative;
  padding: 2rem;
  border: 1px solid $grey;
  margin: 1rem 0;
  background-color: white;

  
  &.warning {
    border: 1px solid $amber;
    background-color: $amber-light;
    
    i {
      margin-top: 3px;
      color: $amber-dark;
    }
  }
  
  &.info {
    border: 1px solid $linkedin;
    background-color: $blue-light;
    
    i {
      margin-top: 5px;
      color: $linkedin;
    }
  }
  
  .icon-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .icon-text-container {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;

    p {
      margin: 0;
    }
  }
}
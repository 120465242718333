.tabs,
.tabs-component-tabs {
  display: flex;
  list-style-type: none;
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 20%;
  border-bottom: 3px solid $brand-red-mid;
  font-family: $heading-font;

  li:first-child a {
    border-top-left-radius: 3px;
  }

  li:last-child a {
    border-top-right-radius: 3px;
  }

  a {
    position: relative;
    color: $brand-red-mid;
    padding: 1rem 1rem;
    background-color: $brand-red-mid-fade;
    width: 100%;
    min-width: 150px;
    display: inline-block;
    box-sizing: border-box !important;
    text-decoration: none;
    text-align: center;
    transition: 0.2s;

    &[aria-selected='true'],
    &:hover {
      background-color: $brand-red-mid;
      color: white !important;
    }
  }
}

.message {
  position: fixed;
  top: 10px;
  right: -100vw;
  width: 350px;
  min-height: 40px;
  text-align: center;
  z-index: 100;
  padding: 10px;
  border-radius: 3px;
  background-color: $brand-red;
  color: #fff;
  font-weight: 500;
  transition: right 0.6s, top 0.6s;

  @media (max-width: 500px) {
    top: -100vh;
    right: 10px;
  }

  &.open {
    right: 10px;

    @media (max-width: 500px) {
      top: 10px;
      left: 10px;
      width: auto;
    }
  }
}

.message.success {
  background-color: $success-light;
  color: $success;
}

.message.error {
  background-color: $error-light;
  color: $error;
}

.message.warn {
  background-color: $amber-light;
  color: $amber;
}
.items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 10px;
  max-height: 200px;
  overflow-y: auto;

  @media (min-width: 450px) {
    max-height: 330px;
  }

  @include scrollbar(5px);
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;

  @media (max-width: 550px) {
    width: 100%;
    max-width: none;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.item__name {
  font-weight: 300;
  color: #3c3b3b;
}

.item__note {
  margin-top: 10px;
  font-size: 0.9em;
  font-style: italic;
  color: $grey-mid;
  max-width: 250px;
}

.v-spinner {
  color: $brand-red-mid;
  text-align: center;
  padding: 100px;
}

.v-spinner--no-spacing {
  text-align: left;
  padding: 0;
}

.v-spinner--report-loading {
  padding: 0;

  @media (max-width: 929px) {
    padding-top: 20px;
  }
}

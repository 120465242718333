.incident-outer-container {
  padding: 0 1rem;

  .button-link {
    margin: 0;
  }

  .incident {
    width: 100%;
    padding: 1rem;
    background: white;

    a {
      cursor: pointer;
    }

    .page__header {
      margin-bottom: 0;
      padding-bottom: 2rem;

      .page__actions {
        display: flex;
        align-items: flex-end;
        gap: 1rem;
        padding-bottom: 1rem;

        a {
          margin: 0;
          font-size: 1rem;
          align-self: unset;
          padding-bottom: 3px;
        }
      }
    }

    .page__actions {
      padding-bottom: 1rem;
    }
  }
}

.incident__details {
  display: flex;
  margin-top: 10px;

  @media (min-width: 440px) {
    margin-top: 0;
  }

  font-size: 16px;
}

.incident__detail {
  margin-right: 10px;
}

.incident__copy {
  margin-bottom: 30px;
}

.incident__contact-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 20px;
  background-color: $grey-light;
  border: 1px solid $grey;
  font-size: 16px;
  line-height: 2;

  >div {
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 20px;

    @media (max-width: 800px) {
      align-items: flex-start;
      margin-left: 0;
    }

    @media (max-width: 500px) {
      margin-bottom: 10px;
    }
  }

  i {
    margin-right: 10px;
  }
}
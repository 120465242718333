@mixin button($bgColour, $textColour, $bgHoverColour, $textHoverColour) {
  padding: 0.55rem 1.5rem;
  font-weight: 500;
  font-family: $heading-font;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  background-color: $bgColour;
  border: none;
  border-radius: $border-radius;
  color: $textColour !important;
  font-size: 1rem;
  font-weight: 600;

  svg,
  span {
    color: $textColour !important;
  }

  &:hover {
    cursor: pointer;
    background-color: $bgHoverColour;
    color: $textHoverColour !important;
    text-decoration: underline;

    svg,
    span {
      color: $textHoverColour !important;
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: default !important;
    opacity: 0.5;
  }
}
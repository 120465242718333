.view {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.site-gutter {
  position: relative;
  max-width: 100%;
  min-width: 100%;
  padding-left: $container-padding-s !important;
  padding-right: $container-padding-s !important;

  @media (min-width: 1024px) {
    padding-left: $container-padding-m !important;
    padding-right: $container-padding-m !important;
  }
  @media (min-width: 1280px) {
    padding-left: $container-padding-l !important;
    padding-right: $container-padding-l !important;
  }
}

.container {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 2rem;
}
.padding-1 {
  padding: 1rem;
}

.container__spacing {
  margin-bottom: 30px;
}

.no-margin.no-margin {
  margin: 0;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 0.8em;
}

.text-large {
  font-size: 1.25rem;
}

.light {
  color: $brand-red-dark;
  font-weight: normal;
}

.strong {
  font-weight: 500;
}

.inactive {
  opacity: 0.2;
}

.note {
  margin-left: 10px;
  color: $brand-red-dark;
  font-size: 0.8rem;

  &-incident {
    margin-left: 0;
    margin-bottom: 5px;
  }
}

.flex,
.f {
  display: flex;
  &-row,
  &-r {
    display: flex;
    flex-direction: row;
    &-wrap,
    &-w {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &-reverse,
      &-r {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
      }
    }
  }
  &-column,
  &-c {
    display: flex;
    flex-direction: column;
    &-wrap,
    &-w {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      &-reverse,
      &-r {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap-reverse;
      }
    }
  }
}

.flex--vert-center {
  align-items: center;
}

.flex--horiz-center {
  justify-content: center;
}

.show-for-small {
  @media (min-width: 500px) {
    display: none !important;
  }
}

.hide-for-small {
  @media (max-width: 500px) {
    display: none !important;
  }
}

.spacing.spacing {
  margin-top: 40px;
}

.spacing-small {
  margin-top: 20px;
}

.spacing-bottom {
  margin-bottom: 40px;
}

.spacing-left {
  margin-left: 10px;
}

.no-spacing {
  margin: 0;
}

.preserve-whitespace {
  white-space: pre-wrap;
}

.error {
  font-size: 0.9em;
  color: $error;
}

// These have been made to deal with CSP errors.  Could perhaps be removed in future
.absolute {
  position: absolute;
}

.admin {
  margin-bottom: 40px;
}

.admin__header {
  background: white;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  h1 {
    margin-bottom: 0;
  }
}

.admin__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.admin__company {
  display: flex;
  align-items: baseline;
}

.admin__breadcrumbs {
  display: flex;
  align-items: center;
}
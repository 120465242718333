.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    text-align: center;
  }

  &.dz-started {
    justify-content: flex-start;
    flex-basis: 20%;
    flex-wrap: wrap;

    .dz-message {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      flex-basis: 100%;
      margin: 10px auto;

      span {
        display: inline-flex;
        flex-direction: column;
      }
    }
  }

  .dz-preview {
    min-width: auto;
    max-width: 140px;
    margin: 10px;

    &.dz-image-preview {
      flex-grow: 1;
    }

    .dz-image img {
      width: 140px;
      height: 140px;
    }

    .dz-details {
      padding-top: 10px;

      .dz-size {
        margin-bottom: 0;
        strong {
          color: #fff;
        }
      }
    }

    .dz-remove {
      pointer-events: auto;
      opacity: 1;
      width: 110px;
      background: rgb(24, 24, 24, 0.3);
    }

    .dz-error-mark,
    .dz-success-mark {
      top: 10px !important;
      left: auto;
      right: 10px;
      width: auto !important;

      i {
        font-size: 2rem !important;
      }
    }

    .dz-error-message {
      top: 35%;
      left: 35%;
    }
  }

  &.dz-disabled {
    pointer-events: none;
    cursor: default;

    .dz-message,
    .dz-remove {
      display: none;
    }
  }
}

.vue-dropzone.vue-dropzone {
  font-family: inherit;
}

.header {
  margin-bottom: 40px;
  padding: 15px 0;
  background: white;
  color: $grey-mid;
}

.header__inner {
  @media (min-width: 930px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.header__logo {
  display: block;
  height: 50px;

  @media (max-width: 930px) {
    margin: 0 auto;
  }
}

.header__info,
.header__info > div {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 930px) {
    justify-content: center;
    margin-top: 10px;
  }
}

.header__info > div {
  @media (max-width: 550px) {
    flex-basis: 100%;
  }
}

.header__info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (max-width: 400px) {
    align-items: center;
  }

  a {
    color: $grey-mid;
  }

  @media (max-width: 930px) {
    margin-top: 10px;
  }
}

.header__actions.header__actions {
  @media (max-width: 550px) {
    display: block;

    .header__info-section {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.environment-badge {
  background-color: #cc0000;
  color: white;
  padding: 5px 10px;
  margin-left: 1rem;
  p {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
}
